.typing-text {
    overflow: hidden;
    white-space: nowrap;
    font-size: 1.2em;
    animation: typing 3s steps(30, end), blink 1s step-end infinite;
    text-align: center;
    margin: 0 auto;
    width: 0;
    animation-iteration-count: infinite;
  }
  
  @keyframes typing {
    0% { width: 0; }
    100% { width: 100%; }
  }
  
  @keyframes blink {
    from, to { border-color: transparent; }
    50% { border-color: white; }
  }

